<template>
  <div class="rechargelist">
    <van-nav-bar left-arrow @click-left="onClickLeft">
      <template #title>{{title}}</template>
    </van-nav-bar>
    <van-list
      v-if="!nondata"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getLogs"
    >
      <van-cell-group :border="false" v-for="(item, index) in list" :key="index">
        <van-cell :border="false" title-class="title_class">
          <template #title>
            {{item.remark}}
          </template>
          <template #right-icon>
            <span>{{item.num}}</span>
          </template>
        </van-cell>
        <van-cell :border="false" title-class="date_class">
          <template #title>
            {{item.createtime_str}}
          </template>
          <template #right-icon>
            {{item.surplus}}
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <van-empty v-if="nondata" description="暂无记录" />

    <div class="withdraw" v-if="type == 'credit2'">
      <van-button @click="withdraw" block type="danger" round>申请提现</van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "Accountlog",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      type: '',
      list: [],
      CurrOffset: 0,
      CurrSize: 20,
      loading: false, // 本次加载是否完毕
      finished: false, // 是否查询完成
      nondata: false,
      title: '变动记录'
    }
  },
  mounted() {
    this.type = this.$route.query.type ? this.$route.query.type : '';
    if(this.type == 'credit1'){
      this.title = '积分变动记录';
    }else if(this.type == 'credit2'){
      this.title = '余额变动记录';
    }else if(this.type == 'credit3'){
      this.title = '活跃分变动记录';
    }else if(this.type == 'credit4'){
      this.title = '惠宝变动记录';
    }
  },
  activated() {
    this.getLogs()
  },
  methods: {
    getLogs() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "team/user/get_credit_list",
          _that.$qs.stringify({
            credittype: _that.type,
            size: _that.CurrSize,
            offset: _that.CurrOffset,
          })
        )
        .then(function(response) {
          console.log(response.data.data, _that.CurrSize);
          _that.loading = false;
          if (response.data.code == 100000) {
            _that.nondata = false;
            if (response.data.data && response.data.data.length > 0) {
              if (_that.CurrOffset == 0) {
                _that.list = response.data.data;
              }else{
                response.data.data.forEach(item => {
                  _that.list.push(item);
                })
              }

              if (response.data.data.length >= _that.CurrSize) {
                _that.CurrOffset += _that.CurrSize;
                _that.finished = false; // 是否查询完成
              }else {
                _that.CurrOffset += response.data.data.length;
                _that.finished = true; // 是否查询完成
              }
            }else {
              _that.finished = true; // 是否查询完成
            }
          }else {
            _that.finished = true; // 是否查询完成
            if (_that.CurrOffset == 0) {
              _that.list = [];
              _that.$toast(response.data.msg ? response.data.msg : "暂无记录");
              _that.nondata = true;
            }
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    // 申请提现
    withdraw(type) {
      this.$router.push({
        name: "Withdraw",
        query: {
          t: new Date().getTime()
        }
      })
    },

    // 返回上一页
    onClickLeft() {
      this.$router.back();
    }
  }
}
</script>
<style lang="less">
.rechargelist {
  width: 100%;
  min-height: 100%;
  background-color: #ededed;
  padding-bottom: 60px;
  .van-nav-bar {
    .van-icon {
      color: #333;
    }
  }
  .van-list {
    background-color: #fff;
    .van-cell-group {
      padding: 0;
      .van-cell {
        .van-cell__title {
          flex: 1;
          text-align: left;
        }
        &:last-of-type {
          padding-top: 0;
          border-bottom: 1px solid #eee;
          color: #999;
          font-size: 12px;
        }
        span {
          margin-left: 5px;
        }
      }
    }
  }
  .withdraw {
    margin: 20px 16px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 50px;
  }
}
</style>